import { makeAutoObservable } from "mobx";

class DataStore {


  loading = false;
  text = "hello"

  constructor() {
    makeAutoObservable(this);
  }

  setField(key, value) {
    this[key] = value
  }

  freeze() {
    this.loading = true
  }

  unfreeze() {
    this.loading = false
  }

}

const dataStore = new DataStore();
export default dataStore;