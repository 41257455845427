import React from 'react';
import './App.css';
import axios from 'axios';
import state from './state.js'
import { observer } from "mobx-react-lite";

function App() {


  const handleClick = async () => {
    try {
      state.freeze()
      const response = await axios.get('https://worker-delicate-pine-62bf.wixfly.workers.dev/');
      // state.setField("text", JSON.stringify(response.data))
      state.text = response.data.display_text
    } catch (error) {
      console.error('Error fetching data', error);
    } finally {
      state.unfreeze()
    }
  };



  const Component1 = observer(() => {
    return (
      <div className="App">
        <div className="text">{state.text}</div>
        <select className="dropdown">
        <option value="option1">Option 1</option>
        <option value="option2">Option 2</option>
        <option value="option3">Option 3</option>
      </select>
      <input type="text" className="textInput" placeholder="Type something..." />
        <button 
          className="button" 
          onClick={handleClick} 
          disabled={state.frozen}
        >
          {state.loading ? 'Please wait...' : 'Click Me'}
        </button>
      </div>
    );
  })

  return (
      <Component1 />
  );

  
}


// function AppComponent() {
//   return (
//     <div className="App">
//       <div className="text">{text}</div>
//       <button 
//         className="button" 
//         onClick={handleClick} 
//         disabled={loading}
//       >
//         {loading ? 'Please wait...' : 'Click Me'}
//       </button>
//     </div>
//   );
// }

export default App;
